import React, { useState ,useEffect} from "react";
import{ Form,Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Home from '../components/Home';
import About from "./About";
import Contact from "./Contact";
import FullTime from "./FullTime";
import Internship from "./Internship";
import PrivacyPolicy from "./PrivacyPolicy";
import QScholar from "./QScholar";
import WebDevelopment from "./WebDevelopment";
const Footer = () => {

  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);

const updateRecordEmail = (event) => {
  //console.log("pp:",event.target.value);
let pp=event.target.value
var pattern=/^\s/;
var pattern2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var isSpace= pattern.test(pp);
  var isEmail= pattern2.test(pp);
  //console.log("isSpace: ",isSpace);
  if(isSpace==false && isEmail){
    setEmailIdError(false);
  }
  else if(isEmail==false){
    setEmailIdError(true);
  }
  setEmailId(pp);
  //console.log("EmailId Error:",emailIdError);
};
const submitData = (event) => {
  if(emailId==''){
   console.log("Empty fields are not allowed!");
   alert("Empty fields are not allowed! Please fill all the details and retry.");
   event.preventDefaults();
   event.stopPropagation();
 }
 else if(emailIdError){
   alert("Invalid Email! Please enter a valid Email Id.");
   event.preventDefaults();
   event.stopPropagation();
 }
 else{
   //submitFunc(emailId);
   //window.location.href = "mailto:info@queccasoft.in?cc=cc@gmail.com&bcc=bcc@gmail.com&subject=Subject&body=Body"
   window.location.href = "mailto:info@queccasoft.in?subject=Subscription Request to Newsletter.Send this mail to Subscribe! &body=Send this mail to subscribe"
 }
}
const submitFunc=(EmailId)=>{
  // UserDataService.submitUserResponse(EmailId)
  //     .then(response => {
  //       alert("Your Message has been sent !");
  //     })
  //     .catch(e => {
  //       alert(e.response.data.message);
  //       console.log(e);
  //     });
}
  return (
    <footer id="footer" className="col-sm-12">

    <div class="footer-newsletter">
      <div class="container col-sm-12">
        <div class="row">
          <div class="col-lg-6">
            <h4>Our Newsletter</h4>
            <p>Subscribe to our Newsletter with your email to get regular updates!</p>
          </div>
          <div class="col-lg-6">
            <Form onSubmit={submitData}>
            <Form.Control
            type="text"
            placeholder="Enter your Email Id"
            value={emailId}
            disabled={false}
            onChange={(e) => updateRecordEmail(e)}
            />
            <Button type="submit">Subscribe</Button>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container col-sm-12">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Overview</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <Link to={"/"} exact component={Home}><a>Home</a></Link></li>
              <li><i class="bx bx-chevron-right"></i> <Link to={"/Overview/About#breadcrumbs"} exact component={About} target="main"><a>About us</a></Link></li>
              <li><i class="bx bx-chevron-right"></i> <Link to={"/Overview/Contact#breadcrumbs"} exact component={Contact} target="main"><a>Contact</a></Link></li>
              <li><i class="bx bx-chevron-right"></i> <Link to={"Careers/FullTime#breadcrumbs"} exact component={FullTime} target="main"><a>Jobs</a></Link></li>
              <li><i class="bx bx-chevron-right"></i> <Link to={"Careers/Internship#breadcrumbs"} exact component={Internship} target="main"><a>Internship</a></Link></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Products</h4>
            <ul>
          <li><i class="bx bx-chevron-right"></i> <Link to={"/Products/QScholar#featured"} exact component={QScholar} target="main"><a>Q-Scholar</a></Link></li>
          <li><i class="bx bx-chevron-right"></i> <Link to={"/Products/WebDevelopment#services"} exact component={WebDevelopment} target="main"><a>Website Development</a></Link></li>
          </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Contact Us</h4>
              <p>
              Kolkata,
              West Bengal,
              India </p> 
              <p><strong>Email:</strong> info@queccasoft.in</p>

          </div>

          <div class="col-lg-3 col-md-6 footer-info">
            <h3>About Queccasoft</h3>
            <p>We provide software products that leverage the newest of technological inovations.Our vision is to help customers achieve their business objectives by providing them with an arsenal of valuable insights through data analysis on a day to day basis.</p>
            <div class="social-links mt-3">
              <a href="https://www.facebook.com/Queccasoft" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="https://instagram.com/queccasoft?igshid=MzRIODBiNWFIZA==" class="instagram"><i class="bx bxl-instagram"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>Queccasoft</span></strong>. All Rights Reserved
      </div>
    </div>
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
  {/* <a href="" class="whatsapp_float d-flex align-items-center justify-content-center"><i class="bi bi-whatsapp"></i></a> */}
  </footer>  
  );
}

export default Footer;
