import React, { useState ,useEffect} from "react";

const PrivacyPolicy = () => {
    return (
        <div>
            <div id="main">
            <div id="breadcrumbs" className="breadcrumbs">
              <div className="container">

                <ol>
                  <li><a>Overview</a></li>
                  <li>Privacy Policy</li>
                </ol>
                <h2>Privacy Policy</h2>

              </div>
            </div>
            
            <div id="portfolio-details" className="portfolio-details">
        <div className="container">

          <div className="row gy-4">

            <div className="col-lg-12">
              {/* <div className="portfolio-info">
                <h3>Project information</h3>
                <ul>
                  <li><strong>Category</strong>: Web design</li>
                  <li><strong>Client</strong>: ASU Company</li>
                  <li><strong>Project date</strong>: 01 March, 2020</li>
                  <li><strong>Project URL</strong>: <a href="#">www.example.com</a></li>
                </ul>
              </div> */}
              <div className="portfolio-description">
                <h2>Privacy Policies</h2>
                <p>
                  <ul>
                    <li>
                      <h1>Introduction</h1>
                      <h6>Queccasoft ("we" or "us") is committed to protecting your privacy and the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and store your information when you use our software and website. Please read this Privacy Policy carefully before using our software or website. By using our software or website, you signify your agreement to this Privacy Policy.</h6>
                    </li>
                    <li>
                      <h1>Information We Collect</h1>
                      <h6>We may collect the following types of information from you:

Personal Information: This includes information that can be used to identify you, such as your name, email address, postal address, phone number, and payment information.
Usage Data: This includes information about how you use our software and website, such as the features you use, the pages you visit, and the time you spend using our software or website.
Device Information: This includes information about the device you use to access our software or website, such as your device type, operating system, and IP address.</h6>
                    </li>
                    <li>
                      <h1>How We Use Your Information</h1>
                      <h6>We may use your information for the following purposes:
                        <ul>
                          <li>To provide and operate our software and website</li>
                          <li>To send you important information about our software and website, such as updates and security alerts</li>
                          <li>To improve our software and website</li>
                          <li>To personalize your experience on our software and website</li>
                          <li>To respond to your inquiries and requests</li>
                          <li>To fulfill any legal obligations</li>
                          </ul>
                          </h6>
                    </li>
                    <li>
                      <h1>How We Share Your Information</h1>
                      <h6>We may share your information with the following third parties:</h6>
                      <ul>
                      <li>Service providers: We may share your information with service providers who help us operate our software and website, such as cloud storage providers and analytics providers.</li>
                      <li>Legal requirements: We may disclose your information if we are required to do so by law, such as in response to a court order or subpoena.</li>
                      <li>Business transactions: We may disclose your information if we are involved in a business transaction, such as a merger, acquisition, or sale of assets.</li>
                      </ul>
                    </li>
                    <li>
                      <h1>Data Retention</h1>
                      <h6>We will retain your information for as long as it is necessary to fulfill the purposes for which it was collected. We may also retain your information for longer periods if necessary to comply with legal requirements or for archival purposes.</h6>
                    </li>
                    <li>
                      <h1>Security</h1>
                      <h6>We have implemented reasonable technical and organizational measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your information.</h6>
                    </li>
                    <li>
                      <h1>Children's Privacy</h1>
                      <h6>Our software and website are not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. We will take steps to delete the information from our records.</h6>
                    </li>
                    <li>
                      <h1>International Transfers</h1>
                      <h6>Your information may be transferred to and processed in countries other than your own. These countries may have different data protection laws than your own country. However, we will take appropriate steps to ensure that your information is treated securely and in accordance with this Privacy Policy.</h6>
                    </li>
                    <li>
                      <h1>Changes to this Privacy Policy</h1>
                      <h6>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</h6>
                    </li>
                   
                  </ul>
                </p>
              </div>
            </div>

          </div>

        </div>
      </div>
</div>
</div>
 );
};

export default PrivacyPolicy;