import React, { useState ,useEffect} from "react";
import{Container,Row,Form,Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Home from '../components/Home';
import image1 from '../Assets/Header/Cloud_01.png';
import ContactFormService from "../services/ContactFormService";
const Contact = () => {
  const [School,setSchool]=useState([]);
  const [currentSchool,setCurrentSchool]=useState(
    {
      id:0,
	name:"Name of Company ",
	contactPerson:"Your Name ",
	personDesignation:"Your Designation ",
	personPhones:"Phone Nos seperated by , ",
	personEmail:"Your Email Id ",
	contactDate:"yyyy-mm-dd ",
	budget:"What is your Budget ",
	requirement:"State your requirement "
    }
  );
  const [name, setName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personEmailError, setPersonEmailError] = useState(false);
  const [personPhones, setPersonPhones] = useState("");
  const [personDesignation, setPersonDesignation] = useState("");
  const [contactDate, setContactDate] = useState("");
  const [budget, setBudget] = useState("");
  const [requirement, setRequirement] = useState("");
  const [message, setMessage] = useState();
  
  //CAPTCHA VARIABLES
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10)); // Random number 0-9
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [userAnswer, setUserAnswer] = useState('');

  const carouselItems = [
    { image: image1, caption: 'This is the first carousel slide' },
    { image: image1, caption: 'This is the second carousel slide' },
    { image: image1, caption: 'This is the third carousel slide' },
  ];

const updateRecordName = (event) => {
  let pp=event.target.value
  var pattern=/^\s/;
  var isSpace= pattern.test(pp);
  //console.log("isSpace: ",isSpace);
  if(isSpace==false){
    setName(pp);    
  }
};

useEffect(() => {
  setMessage();
}, []);

const updateRecordEmail = (event) => {
  //console.log("pp:",event.target.value);
let pp=event.target.value
var pattern=/^\s/;
var pattern2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var isSpace= pattern.test(pp);
  var isEmail= pattern2.test(pp);
  //console.log("isSpace: ",isSpace);
  if(isSpace==false && isEmail){
    setPersonEmailError(false);
  }
  else if(isEmail==false){
    setPersonEmailError(true);
  }
  setPersonEmail(pp);
  //console.log("PersonEmail Error:",personEmailError);
};
const updateRecordPersonPhones = (event) => {
  let pp=event.target.value
  var pattern=/^\s/;
  var isSpace= pattern.test(pp);
  //console.log("isSpace: ",isSpace);
  if(isSpace==false){
    setPersonPhones(pp);    
  }
};
const updateRecordContactDate = (event) => {
  let pp=event.target.value
  var pattern=/^\s/;
  var isSpace= pattern.test(pp);
  //console.log("isSpace: ",isSpace);
  if(isSpace==false){
    setContactDate(pp);    
  }
};
const updateRecordRequirement = (event) => {
  let pp=event.target.value
  var pattern=/^\s/;
  var isSpace= pattern.test(pp);
  //console.log("isSpace: ",isSpace);
  if(isSpace==false){
    setRequirement(pp);    
  }
};
const updateRecordPersonDesignation = (event) => {
  let pp=event.target.value
  var pattern=/^\s/;
  var isSpace= pattern.test(pp);
  //console.log("isSpace: ",isSpace);
  if(isSpace==false){
    setPersonDesignation(pp);    
  }
};
const updateRecordBudget = (event) => {
  let pp=event.target.value
  var pattern=/^\s/;
  var isSpace= pattern.test(pp);
  //console.log("isSpace: ",isSpace);
  if(isSpace==false){
    setBudget(pp);    
  }
};
const updateRecordContactPerson = (event) => {
  let pp=event.target.value
  var pattern=/^\s/;
  var isSpace= pattern.test(pp);
  //console.log("isSpace: ",isSpace);
  if(isSpace==false){
    setContactPerson(pp);    
  }
};
const submitData = (event) => {
  if(name=='' || contactPerson=='' || personDesignation=='' || personEmail=='' || personPhones=='' || requirement=='' || userAnswer==''){
   console.log("Empty fields are not allowed!");
   alert("Empty fields are not allowed! Please fill all the details and retry.");
   event.preventDefaults();
   event.stopPropagation();
 }
 else if(personEmailError){
   alert("Invalid Email! Please enter a valid Email Id.");
   event.preventDefaults();
   event.stopPropagation();
 }
 else if(userAnswer != (num1 + num2)){
  alert("Invalid CAPTCHA! Please enter the valid CAPTCHA.");
  event.preventDefaults();
  event.stopPropagation();
}
 else{
  var currentDate=new Date(2022,3,1);
  //let dt=""+currentDate.getFullYear()+"-"+currentDate.getMonth()+"-"+currentDate.getDate();
  let dt=dateToString(currentDate);
  console.log("currentDate",currentDate,"dt ",dt);
  var xx=
  {
    id:0,
name:name,
contactPerson:contactPerson,
personDesignation:personDesignation,
personPhones:personPhones,
personEmail:personEmail,
requirement:requirement
  }
  console.log("xx ",xx);
  alert("check data ");
  //setCurrentSchool(xx);
   submitFunc(xx);
 }
}
const dateToString=(cDate)=>{
  let dd=cDate.getDate();
  let mm=cDate.getMonth();
  let yyyy=cDate.getFullYear();
  
  let strdd="";
  let strmm="";
  let stryyyy=""+yyyy;
  if(mm<10)
     strmm="0"+mm;
  else
     strmm=""+mm;
  if(dd<10)
     strdd="0"+dd;
  else
     strdd=""+dd;
  let strDate=stryyyy+"-"+strmm+"-"+strdd;
  return strDate;
}

const submitFunc=(xx)=>{
  ContactFormService.createContactForm(xx)
      .then(response => {
        //setMessage("Your Requirement has been sent !")
        alert("Thanks, Our Representative will contact you");
      })
      .catch(e => {
        setMessage("Error Check Entry !");
      });

}
  return (
<div>

<div id="main">

    <div id="breadcrumbs" className="breadcrumbs">
      <div className="container">

        <ol>
          <li><a>Overview</a></li>
          <li>Contact</li>
        </ol>
        <h2>Contact</h2>

      </div>
    </div>

    <div id="contact" className="contact">
      <div className="container">

        <div className="row">
          <div className="col-lg-9">
            <div className="info-box mb-4">
              <i className="bx bx-map"></i>
              <h3>Our Address</h3>
              <p>India</p>
              <p>Kolkata, West Bengal</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <p>info@queccasoft.in</p>
            </div>
          </div>

          {/* <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bxl-instagram"></i>
              <h3>WhatsApp Us</h3>
              <p>9830033325 </p>
            </div>
          </div> */}

        </div>

        <div className="row">

          <div className="col-lg-12">
          <Form onSubmit={submitData} className="php-email-form">
            <Row>
            
            <Container className="form-group">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Official Company/Institution Name "
            value={name}
            disabled={false}
            onChange={(e) => updateRecordName(e)
            }
            required
            />
            </Container>
            <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Your Name"
            value={contactPerson}
            disabled={false}
            onChange={(e) => updateRecordContactPerson(e)
            }
            required
            />
            </Container>
            <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Your Designation"
            value={personDesignation}
            disabled={false}
            onChange={(e) => updateRecordPersonDesignation(e)
            }
            required
            />
           </Container>
         
            <Container className="form-group mt-3 mt-md-0">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Official Email"
            value={personEmail}
            disabled={false}
            onChange={(e) => updateRecordEmail(e)
            }
            required
            />
            </Container>
            </Row>

            <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Official Contact/Phone Nos seperated by , "
            value={personPhones}
            disabled={false}
            onChange={(e) => updateRecordPersonPhones(e)
            }
            required
            />
            </Container>
           
            <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Requirement"
            value={requirement}
            disabled={false}
            onChange={(e) => updateRecordRequirement(e)
            }
            required
            />
            </Container>

            <h3>What is {num1} + {num2}?</h3>
            <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="number"
            placeholder="CAPTCHA ANSWER"
            value={userAnswer}
            disabled={false}
            onChange={(e) => setUserAnswer(e.target.value)
            }
            required
            />
            </Container>

            <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-requirement"></div>
                <div className="sent-requirement">{message && {message}}</div>
              </div>
              <div className="text-center"><Button type="submit">Submit Requirement</Button></div>

            
          </Form>

            {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="personPhones" id="personPhones" placeholder="PersonPhones" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="requirement" rows="5" placeholder="Requirement" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-requirement"></div>
                <div className="sent-requirement">Your requirement has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Requirement</button></div>
            </form> */}

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
  );
};

export default Contact;