import React from 'react';
import image2 from '../Assets/Header/logo.png';
function Internship() {
  return (
    <div>
      <div id="main">

<div id="breadcrumbs" className="breadcrumbs">
  <div className="container col-sm-12">

    <ol>
      <li><a>Careers</a></li>
      <li>Internship</li>
    </ol>
    <h2>Internship</h2>

  </div>
</div>

<div id="portfolio" className="portfolio">
  <div className="row m-3">
  <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
  <div class="portfolio-info border border-danger rounded p-2">
  <h3>Sales Internship</h3>
              <ul>
                <li><strong>Internship Type</strong>: Full-Time paid Internship</li>
                <li><strong>Total Term of Internship</strong>: 6 months</li>
                <li><strong>Pre-Placement Offer</strong>: Available after completion of Internship.</li>
                <li><strong>Shift</strong>: Day</li>
                <li><strong>Schedule</strong>: Mon-Sat</li>
                <li><strong>Roles</strong>: 
                <ul>
                <li>Conduct market survey and cold calling.</li>
                <li>Generate leads and show demo of our software to schools.</li>
                <li>Conduct a regular Follow up with customers and academic institutions.</li>
                <li>Identify potential clients and educational institutions that could benefit from our software solution.</li>
                <li>Reach out to decision-makers, educators, and administrators to introduce our software and demonstrate its value.</li>
                </ul>
                </li>
                <li><strong>Apply</strong>: <a href="https://docs.google.com/forms/d/e/1FAIpQLSefWYDRjJNZrYzHOwMBniZQ6Ethz4mkO7Ww93OTjkY4mTZUzA/viewform?usp=sf_link">CLICK HERE</a></li>
              </ul>
            </div>
</div>
<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
<div class="portfolio-info border border-danger rounded p-2">
<h1>NO POSTS AVAILABLE</h1>
<h4>Check again later</h4>  
</div>
</div>
<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
<div class="portfolio-info border border-danger rounded p-2">
<h1>NO POSTS AVAILABLE</h1>
<h4>Check again later</h4>  
</div>
</div>
{/* <h1>NO POSTS AVAILABLE</h1>
<h4>Check again later</h4> */}
  </div>
</div>
</div>
    </div>
  );
}

export default Internship;
