import React from 'react';
import image2 from '../Assets/Header/logo.png';
function FullTime() {
  return (
    <div>
      <div id="main">

<div id="breadcrumbs" className="breadcrumbs">
  <div className="container col-sm-12">

    <ol>
      <li><a>Careers</a></li>
      <li>FullTime</li>
    </ol>
    <h2>FullTime</h2>

  </div>
</div>

<div id="portfolio" className="portfolio">
  <div className="row m-3">
    
<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="portfolio-info border border-danger rounded p-2">
              <h3>Software Support Personal</h3>
              <ul>
                <li><strong>Job Type</strong>: Full-Time</li>
                <li><strong>Probation Term</strong>: 6 months</li>
                <li><strong>Shift</strong>: Day</li>
                <li><strong>Schedule</strong>: Mon-Sat</li>
                <li><strong>Roles</strong>: 
                <ul>
                <li>Customer Support Online & Offline</li>
                <li>Software Demonstration</li>
                </ul>
                </li>
                <li><strong>Apply</strong>: <a href="https://docs.google.com/forms/d/e/1FAIpQLSeWPkZn4K3deXmbDEXxLsD4gedq3fayN8iaX_F2eW9cwqNxyg/viewform?usp=sf_link">CLICK HERE</a></li>
              </ul>
            </div>
</div>

<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
<div class="portfolio-info border border-danger rounded p-2">
<h3>Frontend Developer</h3>
              <ul>
                <li><strong>Job Type</strong>: Full-Time</li>
                <li><strong>Probation Term</strong>: 6 months</li>
                <li><strong>Shift</strong>: Day</li>
                <li><strong>Schedule</strong>: Mon-Sat</li>
                <li><strong>Roles</strong>: 
                <ul>
                <li>UI development</li>
                <li>Technologies: React Js, CSS and HTML</li>
                </ul>
                </li>
                <li><strong>Apply</strong>: <a href="https://docs.google.com/forms/d/e/1FAIpQLSfg0Yjs8Z0fWjM8RIGB9yUWcm1V2yfk0rqxaOv94X2NzqOJng/viewform?usp=sf_link">CLICK HERE</a></li>
              </ul>  
</div>
</div>
<div class="col-lg-4 col-md-6 d-flex align-items-stretch">
<div class="portfolio-info border border-danger rounded p-2">
<h1>NO POSTS AVAILABLE</h1>
<h4>Check again later</h4>  
</div>
</div>
{/* <h1>NO POSTS AVAILABLE</h1>
<h4>Check again later</h4> */}
  </div>
  
</div>
</div>
    </div>
  );
}

export default FullTime;
