import http from "../http-common";

const getAllSchool = () => {
  return http.get(`/school`);
};

const getSchoolById = (id) => {
  return http.get(`/school/${id}`);
};
const createSchool = (data) => {
  console.log("data ",data);
  return http.post(`/special/school`,data);
};

const editSchoolById = (id,data) => {
  return http.put(`/school/${id}`,data);
};
const deleteSchoolById = (id) => {
  return http.delete(`/school/${id}`);
};
const SchoolService = {
  getAllSchool,
  getSchoolById,
  createSchool,
  editSchoolById,
  deleteSchoolById
};

export default SchoolService;
