import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import qscholarLogo from "../Assets/Header/Q-SCHOLAR LOGO.png"
import quampaignLogo from '../Assets/Header/logo.png'
function PopUp() {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/productsMenu/qscholar`; 
    navigate(path);
    handleClose()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Get a free demo of Q-Scholar!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="featured" className="featured">
      <div className="container mt-1">

        <div className="row">
          <div className="col-lg-12">
            <div className="icon-box">
              <i><img src={qscholarLogo} className='col-sm-2'></img></i>
              <h3><a>Q-SCHOLAR</a></h3>
              <h3><a>School ERP | Academic Management | Report Card Software</a></h3>
              <h5>Boost Your Academic Institution with Q-Scholar ERP.</h5>
                  <Link to="/Products/QScholar#contact" target="main">
                  <Button className='btn-get-started' onClick={handleClose}>BOOK FREE DEMO</Button>
                  </Link>
            </div>
          </div>
        </div>

      </div>
    </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-danger" onClick={routeChange}>Try it out for free!</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default PopUp;