import React from 'react';
import image2 from '../Assets/Header/logo.png';
function Tenders() {
  return (
    <div>
      <div id="main">

<div id="breadcrumbs" className="breadcrumbs">
  <div className="container col-sm-12">

    <ol>
      <li><a>Notices</a></li>
      <li>Tenders</li>
    </ol>
    <h2>Tenders</h2>

  </div>
</div>

<div id="about" className="about">
  <div className="container col-sm-12">
<center>
<h1>NO TENDERS AVAILABLE</h1>
<h4>Check again later</h4>
</center>
  </div>
</div>
</div>
    </div>
  );
}

export default Tenders;
