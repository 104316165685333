import React from 'react';
import image2 from '../Assets/Header/logo.png';
function About() {
  return (
    <div>
      <div id="main">

<div id="breadcrumbs" className="breadcrumbs">
  <div className="container">

    <ol>
      <li><a>Overview</a></li>
      <li>About Us</li>
    </ol>
    <h2>About Us</h2>

  </div>
</div>

<div id="about" className="about">
  <div className="container">

    <div className="row">
      <div className="col-lg-6">
        <img src={image2} className="img-fluid" alt=""/>
      </div>
      <div className="col-lg-6 pt-4 pt-lg-0 content">
        <h3>Welcome to Queccasoft ERP Solutions!</h3>
        <p className="fst-italic">

          At Queccasoft ERP Solutions, we are dedicated to transforming businesses through cutting-edge Enterprise Resource Planning (ERP) software. With a steadfast commitment to excellence and innovation, we empower organizations of all sizes to streamline their operations, boost productivity, and stay ahead in today's competitive business landscape.
          
          <h3>Our Story:</h3>
          Founded in 2023, our journey began with a vision to revolutionize how businesses manage their resources, processes, and data across diverse industries, gaining valuable insights and understanding their unique challenges and requirements.
          
          <h3>What We Offer:</h3>
          We develop proprietary ERP software suite which designed to meet the evolving needs of modern businesses.Apart from proprietary softwares and web apps we also offer our own software products which are ready to deploy. With our cloud based ERP solution, organizations can achieve better visibility, data-driven decision-making, and enhanced collaboration across departments.
          <h3>Our Mission:</h3>
          Our mission is to empower businesses to thrive in the digital era by providing them with the most advanced and reliable ERP software. We strive to foster growth, efficiency, and profitability for our clients through innovative technology and exceptional service.
          <h3>Experience The Difference</h3>
          Join hands with Queccasoft ERP Solutions and embark on a transformative journey that will redefine the way you do business. Embrace a future where efficiency meets effectiveness, where data drives decisions, and where your organization achieves new heights of success.
        </p>
        <p>
          Contact us today to learn more about how Queccasoft ERP Solutions can empower your business for sustained growth and unparalleled success.
        </p>
      </div>
    </div>

  </div>
</div>
</div>
    </div>
  );
}

export default About;
