import React, { useState ,useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import{Nav,Navbar,Container,Button,Row,Form} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import schoolDataService from "../services/SchoolService";
import image1 from '../Assets/Header/Cloud_01.png';
import image2 from "../Assets/Header/qscholar/black_board_01.jpg";
import image3 from "../Assets/Header/qscholar/exam_01.jpg";
import image4 from "../Assets/Header/qscholar/school_01.jpg";
import image5 from "../Assets/Header/qscholar/calendar_01.jpg";
import image6 from "../Assets/Header/qscholar/security_01.jpg";
import ContactFormService from "../services/ContactFormService";
function QScholar() {

  const [School,setSchool]=useState([]);
  const [currentSchool,setCurrentSchool]=useState(
    {
      id:0,
	name:"Name of Company ",
	contactPerson:"Your Name ",
	personDesignation:"Your Designation ",
	personPhones:"Phone Nos seperated by , ",
	personEmail:"Your Email Id ",
	contactDate:"yyyy-mm-dd ",
	budget:"What is your Budget ",
	requirement:"State your requirement "
    }
  );
  const [name, setName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personEmailError, setPersonEmailError] = useState(false);
  const [personPhones, setPersonPhones] = useState("");
  const [personDesignation, setPersonDesignation] = useState("");
  const [contactDate, setContactDate] = useState("");
  const [budget, setBudget] = useState("");
  const [requirement, setRequirement] = useState("");
  const [message, setMessage] = useState();

  //CAPTCHA VARIABLES
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10)); // Random number 0-9
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [userAnswer, setUserAnswer] = useState('');

  const updateRecordName = (event) => {
    let pp=event.target.value
    var pattern=/^\s/;
    var isSpace= pattern.test(pp);
    //console.log("isSpace: ",isSpace);
    if(isSpace==false){
      setName(pp);    
    }
  };
  
  const useEffect = () => {
     setMessage();
  };
  
  const updateRecordEmail = (event) => {
    //console.log("pp:",event.target.value);
  let pp=event.target.value
  var pattern=/^\s/;
  var pattern2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var isSpace= pattern.test(pp);
    var isEmail= pattern2.test(pp);
    //console.log("isSpace: ",isSpace);
    if(isSpace==false && isEmail){
      setPersonEmailError(false);
    }
    else if(isEmail==false){
      setPersonEmailError(true);
    }
    setPersonEmail(pp);
    //console.log("PersonEmail Error:",personEmailError);
  };
  const updateRecordPersonPhones = (event) => {
    let pp=event.target.value
    var pattern=/^\s/;
    var isSpace= pattern.test(pp);
    //console.log("isSpace: ",isSpace);
    if(isSpace==false){
      setPersonPhones(pp);    
    }
  };
  const updateRecordContactDate = (event) => {
    let pp=event.target.value
    var pattern=/^\s/;
    var isSpace= pattern.test(pp);
    //console.log("isSpace: ",isSpace);
    if(isSpace==false){
      setContactDate(pp);    
    }
  };
  const updateRecordRequirement = (event) => {
    let pp=event.target.value
    var pattern=/^\s/;
    var isSpace= pattern.test(pp);
    //console.log("isSpace: ",isSpace);
    if(isSpace==false){
      setRequirement(pp);    
    }
  };
  const updateRecordPersonDesignation = (event) => {
    let pp=event.target.value
    var pattern=/^\s/;
    var isSpace= pattern.test(pp);
    //console.log("isSpace: ",isSpace);
    if(isSpace==false){
      setPersonDesignation(pp);    
    }
  };
  const updateRecordBudget = (event) => {
    let pp=event.target.value
    var pattern=/^\s/;
    var isSpace= pattern.test(pp);
    //console.log("isSpace: ",isSpace);
    if(isSpace==false){
      setBudget(pp);    
    }
  };
  const updateRecordContactPerson = (event) => {
    let pp=event.target.value
    var pattern=/^\s/;
    var isSpace= pattern.test(pp);
    //console.log("isSpace: ",isSpace);
    if(isSpace==false){
      setContactPerson(pp);    
    }
  };
  const submitData = (event) => {
    if(name=='' || contactPerson=='' || personDesignation=='' || personEmail=='' || personPhones=='' || userAnswer==''){
     console.log("Empty fields are not allowed!");
     alert("Empty fields are not allowed! Please fill all the details and retry.");
     event.preventDefaults();
     event.stopPropagation();
   }
   else if(personEmailError){
     alert("Invalid Email! Please enter a valid Email Id.");
     event.preventDefaults();
     event.stopPropagation();
   }
   else if(userAnswer != (num1 + num2)){
    alert("Invalid CAPTCHA! Please enter the valid CAPTCHA.");
    event.preventDefaults();
    event.stopPropagation();
  }
   else{
    var currentDate=new Date(2022,3,1);
    //let dt=""+currentDate.getFullYear()+"-"+currentDate.getMonth()+"-"+currentDate.getDate();
    let dt=dateToString(currentDate);
    console.log("currentDate",currentDate,"dt ",dt);
    var xx=
    {
      id:0,
  name:name,
  contactPerson:contactPerson,
  personDesignation:personDesignation,
  personPhones:personPhones,
  personEmail:personEmail,
  requirement:"REQUEST FOR FREE DEMO"
    }
    console.log("xx ",xx);
    alert("check data ");
    setCurrentSchool(xx);
     submitFunc(xx);
   }
  }
  const dateToString=(cDate)=>{
    let dd=cDate.getDate();
    let mm=cDate.getMonth();
    let yyyy=cDate.getFullYear();
    
    let strdd="";
    let strmm="";
    let stryyyy=""+yyyy;
    if(mm<10)
       strmm="0"+mm;
    else
       strmm=""+mm;
    if(dd<10)
       strdd="0"+dd;
    else
       strdd=""+dd;
    let strDate=stryyyy+"-"+strmm+"-"+strdd;
    return strDate;
  }
  
  const submitFunc=(xx)=>{
    console.log("current ",xx);
    alert("check data 1 ");
    ContactFormService.createContactForm(xx)
        .then(response => {
          setMessage("Your Requirement has been sent !")
          alert("Your Requirement has been sent !");
        })
        .catch(e => {
          setMessage("Error Check Entry !");
          alert(e.response.data.requirement);
          console.log(e);
        });
        alert("check data 2");
  
  }
 
  const carouselItems = [
    { image: image4, caption: 'School Management System with ', spanCaption: "Secure Cloud Storage.",caption2:'Global Access|Mobile Responsive|All Device Compatible', buttonCaption: "Read More",url:''},
    { image: image3, caption: 'ERP that is compatible with all', spanCaption: " State and Private Boards.", caption2:'CBSE|ICSE|ISC|State Boards', buttonCaption: "Read More",url:''},
    { image: image4, caption: 'ERP with ', spanCaption: " +13 features & 10 reports !", caption2:'', buttonCaption: "Read More",url:''},
    { image: image6, caption: 'Reliable Security built with ', spanCaption: "Java Spring MVC", caption2:'As Secure as Banking Technology', buttonCaption: "Read More",url:''}
  ];
  //let navigate = useNavigate(); 
  // const routeChange = () =>{ 
  //   let path = `/`; 
  //   navigate(path);
  // }

  return (
    <div>
      <div id="main">

      {/* <div id="breadcrumbs" className="breadcrumbs">
        <div className="container">

          <ol>
            <li><a>Products</a></li>
            <li>Q-Scholar</li>
          </ol>
          <h2>Q-Scholar</h2>

        </div>
      </div>  */}

      <div id='hero'>
      <Carousel className='carousel-container me-auto' fade>
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={item.image}
              alt={item.caption}
            />
            <Carousel.Caption className='carousel-content'>
              <h2>{item.caption}<span>{item.spanCaption}</span></h2>
              {item.caption2 && <p>{item.caption2}</p>}
              {item.url && <Link to={item.url}>
              <Button className="btn-get-started">{item.buttonCaption}</Button>
              </Link>}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      </div>

      <div id="featured" className="featured">
      <div className="container">

        <div className="row md-4">
          <div className="col-lg-4">
            <div className="icon-box">
              <i className="bi bi-calendar-check"></i>
              <h3><a>Teacher & Student Attendance</a></h3>
              <p>Mark both Student Attendance by section and User Attendance by User Group!</p>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-pie-chart"></i>
              <h3><a>+10 Types of Reports</a></h3>
              <p>Get Reports by standard or section or even by individual subjects!</p>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-calendar-event"></i>
              <h3><a>Routine & Year Planner</a></h3>
              <p>Create and Customise Routine & Year Planner!</p>
            </div>
          </div>
        </div>

        <center>
        <h1>Do more with Q-Scholar !</h1>
        <h6>Create & Customomise Report Templates, create subject combination templates and allot substitution teachers by routine!</h6>
        </center>

        <div className="row mt-4 md-4">
          <div className="col-lg-4">
            <div className="icon-box">
              <i className="bi bi-file-spreadsheet"></i>
              <h3><a>Dynamic Report Templates</a></h3>
              <ul>
              <li>Use Conversion Factors.</li>
              <li>Marks,Scholastic,Remarks & Attendance Tables</li>
              <li>Generate Reports for all standards with one-click!</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-calculator"></i>
              <h3><a>Marks Entry</a></h3>
              <ul>
              <li>Marks Entry Protected with User Access Permission.</li>
              <li>Get Report on Marks Not Entered by User or by Section</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-calendar-minus"></i>
              <h3><a>Substitution</a></h3>
              <ul>
              <li>Substitute Absent users with present users.</li>
              </ul>
            </div>
          </div>
        </div>

        <center>
        <h1>There is more to it!</h1>
        <h6>Create Students & Teacher's Diary, Id Cards.Get Admission enquiry, promote & roll over !</h6>
        </center>

        <div className="row mt-4">
          <div className="col-lg-4">
            <div className="icon-box">
              <i className="bi bi-clipboard-data"></i>
              <h3><a>Diary & ID Card</a></h3>
              <ul>
              <li>Use Conversion Factors.</li>
              <li>Marks,Scholastic,Remarks & Attendance Tables</li>
              <li>Generate Reports for all standards with one-click!</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-arrow-bar-up"></i>
              <h3><a>Promote</a></h3>
              <ul>
              <li>Marks Entry Protected with User Access Permission.</li>
              <li>Get Report on Marks Not Entered by User or by Section</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-recycle"></i>
              <h3><a>Roll-Over</a></h3>
              <ul>
              <li>Roll Over Previous Year Templates to New Year.</li>
              <li>Roll Over Students & Users to New Year.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-4">
            <div className="icon-box">
              <i className="bi bi-file-earmark-spreadsheet"></i>
              <h3><a>Import From Excel</a></h3>
              <ul>
              <li>Import Users from Excel file.</li>
              <li>Import Students from Excel file.</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-filetype-ai"></i>
              <h3><a>Auto Remarks</a></h3>
              <ul>
              <li>AI generated Remarks.</li>
              <li>One-Click Generation of Student Remarks as per performance!</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-mic-fill"></i>
              <h3><a>Voice Assistant</a></h3>
              <ul>
              <li>Use microphone to call out marks during entry!</li>
              <li>Voice operated remarks and marks entry!</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-4">
            <div className="icon-box">
              <i className="bi bi-file-earmark-lock"></i>
              <h3><a>Multi-Factor Authentication</a></h3>
              <ul>
              <li>User Login Portal.</li>
              <li>OTP Authentication.</li>
              <li>Separate User Access Permission & User Roles.</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-calendar4"></i>
              <h3><a>Year Wise Data</a></h3>
              <ul>
              <li>See Year Wise Data for all users,standards,subjects & students.</li>
              <li>Store YearWise Reports Separately.</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-question-circle"></i>
              <h3><a>Admission Enquiry</a></h3>
              <ul>
              <li>Get Admission Enquiry Form on your website.</li>
              <li>View Messages on Q-Scholar Terminal.</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div id="services" className="services">
          <div className="container">

          <center>
        <h1>Key Features & Reliablility</h1>
        <h6>With a User friendly UI to MFA security, Q-Scholar stands as the most reliable Academic SAAS Product.</h6>
        </center>

            <div className="row">
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-phone-incoming"></i></div>
                  <h4><a>Customer Support</a></h4>
                  <p>Facing an issue?Don’t worry! We have got you covered.We provide 24x7 Customer Support and on ground assistance!</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-file"></i></div>
                  <h4><a>Light Code</a></h4>
                  <p>Requires minimum system resources to ensure smooth user experience without any bugs.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-tachometer"></i></div>
                  <h4><a>Reports</a></h4>
                  <p>Our softwares provide statistical reports on day to day basis to ensure maximum efficiency of your business!</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-world"></i></div>
                  <h4><a>Global Access</a></h4>
                  <p>Log in from anywhere you want! Thats the advantage of cloud!</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-shield-plus"></i></div>
                  <h4><a>Security</a></h4>
                  <p>Our Cloud Services offer multi-layered security features such as Multi-Factor Authentication & Firewall that keeps your database safe and secure.</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-data"></i></div>
                  <h4><a>Backup</a></h4>
                  <p>Automatic data backups and safepoint keep your system safe in case of any major security breach.</p>
                </div>
              </div>
              </div>
          </div>
      </div>

      <div id="portfolio-details" className="portfolio-details">
        <div className="container">

          <div className="row gy-4">

            <div className="col-lg-12">
              <div className="portfolio-description">
                <p>               
                <h2>Level Up Your Organisation With Q-Scholar !</h2>
                Experience the power of Q-Scholar ERP Software and embark on a journey towards streamlined and data-driven report card management. Our software promises to save your school valuable time, resources, and reduce administrative burdens, allowing you to focus on nurturing the next generation of learners.
                We invite you to explore our software further, schedule a personalized demo, and discover how Q-Scholar can elevate your school's academic reporting process to new heights.
                <h3>Register with us today to get a free Demo of Q-Scholar!</h3>
                </p>
              </div>
            </div>

          </div>

        </div>
      </div> 

      <div id="contact" className="contact">
      <div className="container col-sm-6">
      <Form onSubmit={submitData} className="php-email-form">
            <Row>
            
            <Container className="form-group">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="School / Institution Name "
            value={name}
            disabled={false}
            onChange={(e) => updateRecordName(e)
            }
            />
            </Container>
            <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Your Name"
            value={contactPerson}
            disabled={false}
            onChange={(e) => updateRecordContactPerson(e)
            }
            />
            </Container>
            <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Your Designation"
            value={personDesignation}
            disabled={false}
            onChange={(e) => updateRecordPersonDesignation(e)
            }
            />
           </Container>
         
            <Container className="form-group mt-3 mt-md-0">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Institution's Official Email"
            value={personEmail}
            disabled={false}
            onChange={(e) => updateRecordEmail(e)
            }
            />
            </Container>
            </Row>

            <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Institution's Official Contact/Phone Nos seperated by , "
            value={personPhones}
            disabled={false}
            onChange={(e) => updateRecordPersonPhones(e)
            }
            />
            </Container>
           
            {/* <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="text"
            placeholder="Requirement"
            value={requirement}
            disabled={false}
            onChange={(e) => updateRecordRequirement(e)
            }
            />
            </Container> */}

    <h3>What is {num1} + {num2}?</h3>
            <Container className="form-group mt-3">
            <Form.Control
            className="form-control"
            type="number"
            placeholder="CAPTCHA ANSWER"
            value={userAnswer}
            disabled={false}
            onChange={(e) => setUserAnswer(e.target.value)
            }
            required
            />
            </Container>
      
            <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-requirement"></div>
                <div className="sent-requirement">{message && {message}}</div>
              </div>
              <div className="text-center"><Button type="submit">Book A Free Demo</Button></div>

            
          </Form>
          </div>
          </div>

          </div>
    </div>
  );
}

export default QScholar;
