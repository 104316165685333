import React from 'react';
import { Nav, Offcanvas,Button } from 'react-bootstrap';
import { useState ,useEffect} from "react";
import { Link } from 'react-router-dom';
import menuJson from "../menulist.json";

const SidebarComponent = ({cData}) => {
  // ... existing code
  const [showSidebar, setShowSidebar] = useState(false);
	const [tfap_ad, setTfap_ad] = useState(cData);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);
  useEffect(() => {
		console.log("cData into Sidebar",cData)
    console.log("tfap_ad into Sidebar",tfap_ad)
	}, []);
  return (
    <>
      {/* Toggle button to open/close sidebar */}
      <div className='selection'>
			<Button variant="" className="button btn-sidebar-toggle rounded-circle" onClick={handleShowSidebar}>
			<i className="fas fa-bars"></i>
			</Button>
			</div>

      {/* Offcanvas component for the sidebar content */}
      <Offcanvas
        show={showSidebar}
        onHide={handleCloseSidebar}
        backdrop={false}
        placement="start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Navigation</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Add your sidebar links and content here */}
          <Nav>
          <ul class="navbar-nav">
							{!menuJson && <h6>LOADING...</h6> }
							{menuJson && menuJson.map((menuI, i) => (
							<li class="nav-item row" type="button" data-toggle="collapse" data-target={menuI.dataTarget}>
                    
										<a class={(menuI.icon)+" display-6 col-sm-auto animate__animated animate__fadeInLeft"}></a>
                    <h3 class="text-dark col-sm-auto animate__animated animate__fadeInRight" >{menuI.name}</h3>
                    <hr></hr>
							
								<ul class="collapse navbar-nav" id={menuI.id}>
								{menuI.column.map((menuJ,j) => (
									tfap_ad && tfap_ad.charAt(menuJ.index)==='1' &&  
								<li class="nav-item animate__animated animate__fadeInLeft">
                <li onClick={handleCloseSidebar}><Link to={menuJ.link_to}><a className='p-3'>{menuJ.name}</a></Link></li>
								<hr></hr>
								</li> 
								
								))}
								</ul>
							</li>
								))}
							</ul>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SidebarComponent;
{/* <ul>

            <a class="nav-link" href={menuJ.link_to}>{menuJ.name} </a> line 61
<li onClick={handleCloseSidebar}><Link to={"/"} exact component={Home}><a>Home</a></Link></li>
<li onClick={handleCloseSidebar}><Link to={"/products"} exact component={Products}><a>Products</a></Link></li>
<li onClick={handleCloseSidebar}><Link to={"/yearList"} exact component={YearList}><a>YearList</a></Link></li>
</ul> */}