import http from "../http-common";

const getSchool = () => {
  	return http.get(`/setUp`);
};

const getSetUpById = (id) => {
	return http.get(`/admin/setUp/${id}`)
}

const getSetUpYearListId = () => {
	return http.get(`/admin/setUp/yearListId`)
}

const getCurrentYearlist = () => {
	return http.get(`/currentYearList/setUp`)
}

const getMessageBox = () => {
	return http.get(`/messageBox/setUp`)
}

const createSetUp = (yearListId, data) => {
	return http.post(`/creator/yearList/${yearListId}/setUp`, data)
}

const updateSetUp = (id, yearListId, data) => {
	return http.put(`/creator/setUp/${id}/yearList/${yearListId}`, data)
}

const updateCurrentYearList = yearListId => {
	return http.put(`/creator/updateCurrentYearList/setUp/1/yearList/${yearListId}`)
}

const saveMessageBox = (name) => {
	return http.put(`/creator/messageBox/setUp?msg=${name}`)
}

const deleteSetUp = (id) => {
	return http.delete(`/creator/setUp/${id}`)
}

const deleteAllSetUp = id => {
	return http.delete(`/creator/setUpTables/${id}/setUp`)
}

const findByName = (name) => {
	return http.get(`/setUp?name=${name}`);
}

const setUpService = {
  	getSchool,
	getSetUpById,
	getSetUpYearListId,
	getCurrentYearlist,
	getMessageBox,
	createSetUp,
	updateSetUp,
	updateCurrentYearList,
	saveMessageBox,
	deleteSetUp,
	deleteAllSetUp,
	findByName
};

export default setUpService;