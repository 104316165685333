import React, { useState, Fragment } from "react"
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import{Nav,Navbar,Container,Badge,OverlayTrigger,Tooltip} from 'react-bootstrap';
import Home from "./components/Home";
import NoMatch from "./components/NoMatch";
import Footer from './components/Footer';
import Sidebar from "./components/Sidebar";
import "../src/Assets/vendor/animate.css/animate.min.css"; 
import "../src/Assets/vendor/bootstrap/css/bootstrap.min.css"; 
import "../src/Assets/vendor/bootstrap-icons/bootstrap-icons.css"; 
import "../src/Assets/vendor/boxicons/css/boxicons.min.css"; 
import "../src/Assets/vendor/glightbox/css/glightbox.min.css"; 
import "../src/Assets/vendor/swiper/swiper-bundle.min.css";
import AltImg from "../src/Assets/buttons/DISK.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useCallback, useEffect } from 'react';
import SetUpService from "./services/SetUpService";
import logo from "../src/Assets/Header/Q-SCHOLAR LOGO.png";
import OverviewMenu from "./components/OverviewMenu";
import CareersMenu from "./components/CareersMenu";
import NoticesMenu from "./components/NoticesMenu";
import QScholar from "./components/QScholar";
import ProductMenu from "./components/ProductMenu";
//import Chatbot from "./components/Chatbot";
const App = () => {
	//const [pp, setPP] = useState("");
	const [loggedInYearList, setLoggedInYearList] = useState(0);
	const [server,SetServer] = useState(process.env.REACT_APP_SERVER_IP);
	const [portNo,setPortNo]=useState(process.env.REACT_APP_SERVER_PORT);
	const [httpValue,setHttpValue]=useState(process.env.REACT_APP_SERVER_HTTP);
	const [schoolName, setSchoolName] = useState("School Name (MaxLength allowed is 48 characters)");
	const [schoolLogo, setSchoolLogo] = useState("/Assets/buttons/logo.png");
	const [fileUrl, setFileUrl]=useState("/Assets/buttons/DISK.png");
	const [loggedInUser, setLoggedInUser] = useState();
	const [userId, setUserId]=useState();
	const [userName,setUserName]=useState("Not Signed In");
	const [logout, setLogout]=useState(httpValue+"://"+server+":"+portNo+"/logout");
	const [tfap_ad, setTfap_ad] = useState("111111111111111111111111111111111111111111111");

	useEffect(() => { 
		setTfap_ad("111111111111111111111111111111111111111111111");    
	}, []);


	return (
		<div className="col-sm-12 m-0 p-0">
					{/* <PopUp></PopUp> */}
					{/* <Chatbot></Chatbot> */}
			<div className="col-sm-12 m-0 p-0"> 

			<div id="topbar" className="d-flex align-items-center animate__animated animate__fadeInRight">
				<div className="container col-sm-12 d-flex justify-content-center justify-content-md-between">
				<div className="contact-info d-flex align-items-center">
					<i className="bi bi-envelope d-flex align-items-center"><a href="mailto:info@queccasoft.in">info@queccasoft.in</a></i>
					<i className="bi bi-phone d-flex align-items-center ms-4"><a href="https://backend.queccasoft.in">Login</a></i>
				</div>
				<div className="social-links d-none d-md-flex align-items-center">
					<a href="https://www.facebook.com/Queccasoft" className="facebook"><i className="bx bxl-facebook"></i></a>
					<a href="https://instagram.com/queccasoft?igshid=MzRIODBiNWFIZA==" className="instagram"><i className="bx bxl-instagram"></i></a>
				</div>
				</div>
			</div>

			<div id="header" className="d-flex align-items-center animate__animated animate__fadeInLeft">
				<div className="container col-sm-12 d-flex justify-content-between align-items-center">

				<div className="logo">
					<a><Link to={"/"}><img src="/Assets/buttons/Header_01.png" alt="QUECCASOFT" height="100%" width="100%" className="img-fluid h1"></img></Link></a>
				</div>
				<Sidebar id="sidebar" cData={tfap_ad}/>
				</div>
			</div>

			</div>
			<div class="row col-sm-12 p-0 m-0 animate__animated animate__fadeIn">
				
				<Routes>
	 			<Route path="/" element={<Home />} />
				<Route path="*" element={<NoMatch />} />
				<Route path="/Overview/*" element={<OverviewMenu/>} />
				<Route path="/Products/*" element={<ProductMenu/>} />
				<Route path="/Notices/*" element={<NoticesMenu/>} />
				<Route path="/Careers/*" element={<CareersMenu/>} />
				</Routes>

			</div>

			<div>
				<Footer></Footer>
			</div>

		</div>
	);
}
export default App;

