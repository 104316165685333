import http from "../http-common";

const createContactForm = (data) => {
    console.log("into contactForm ",data)
  return http.post(`/special/contactForm`,data);
};

const ContactFormService = {
    createContactForm
  };
  
  export default ContactFormService;