import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import{ Form,Button,Table} from 'react-bootstrap';
import image1 from '../Assets/Header/website/www.jpeg';
import image2 from '../Assets/Header/website/website-monitor.png';
import image3 from '../Assets/Header/website/seo.jpg';
import image4 from '../Assets/Header/website/https.jpg';

const WebDevelopment = () => {

    const carouselItems = [
        { image: image1, caption: 'Showcase your institution across the world on the', spanCaption: " World Wide Web.",caption2:'', buttonCaption: "Read More",url:''},
        { image: image2, caption: 'Heavily Customised and personalised professional', spanCaption: " Websites.", caption2:'Global Access|Mobile Responsive|All Device Compatible', buttonCaption: "Read More",url:''},
        { image: image3, caption: 'Maintained with a professional', spanCaption: " SEO Optimisation", caption2:'Stay on the top of the search with SEO!', buttonCaption: "Read More",url:''},
        { image: image4, caption: 'Reliable Security with ', spanCaption: "SSL Encryption.", caption2:'Secure & Safe against data theft!', buttonCaption: "Read More",url:''}
      ];

      const data = [
        { Feature: "Website Hosting", name: 'Alice', age: 30 },
        { Feature: "Domain Renewal", name: 'Bob', age: 25 },
        { Feature: "SSL Renewal", name: 'Charlie', age: 40 },
      ];

      const tableHeaders = Object.keys(data[0]);

    return (

        <div id="main">

        <div id='hero'>
      <Carousel className='carousel-container me-auto' fade>
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={item.image}
              alt={item.caption}
            />
            <Carousel.Caption className='carousel-content'>
              <h2>{item.caption}<span>{item.spanCaption}</span></h2>
              {item.caption2 && <p>{item.caption2}</p>}
              {item.url && <Link to={item.url}>
              <Button className="btn-get-started">{item.buttonCaption}</Button>
              </Link>}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      </div>

    <div id="services" class="services">
        <div class="container">
  
          <div class="row">
          
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div class="icon-box">
                <div class="icon"><i class="bx bx-news"></i></div>
                <h4><a>Basic Website</a></h4>
                <p>Best for individuals and small businesses.</p>
                <p>______________________________________________</p>
                <p>
                <ul>
                    <p><h4>Features</h4></p>
                        <li className="bi-check-circle-fill text-success"> Max Pages: 10</li>
                        <li className="bi-check-circle-fill text-success"> Contact Form</li>
                        <li className="bi-check-circle-fill text-success"> Mobile Responsive Website</li>
                        <li className="bi-check-circle-fill text-success"> Carousel Feature</li>
                        <li className="bi-check-circle-fill text-success"> Navigation Sidebar</li>
                        <li className="bi-check-circle-fill text-success"> Footer Section</li>
                        <li className="bi-check-circle-fill text-success"> Static Gallery</li>
                        <li className="bi-check-circle-fill text-success"> Testimonials</li>
                        <li className="bi-check-circle-fill text-success"> Animated Text Effects</li>
                        <li className="bi-check-circle-fill text-success"> Control Pannel</li>
                        <li className="bi-check-circle-fill text-success"> MFA protected User Login</li>
                        <li className="bi bi-x-circle-fill text-danger"> Search bar</li>
                    <p><h4>Extra Benefits</h4></p>
                        <li className="bi-check-circle-fill text-success"> 1 Free Domain Registration</li>
                        <li className="bi-check-circle-fill text-success"> Free SSL Certification(https://)</li>
                        <li className="bi-check-circle-fill text-success"> 3 Free Revisions Monthly</li>
                        <li className="bi-check-circle-fill text-success"> 1 Free Professional email(1 GB)</li>
                        <li className="bi bi-x-circle-fill text-danger"> Newsletter Subscribe button</li>
                    <p><h4>Social Media integration </h4></p>
                        <li className="bi-check-circle-fill text-success"> WhatsApp Integration</li>
                        <li className="bi-check-circle-fill text-success"> FaceBook Integration</li>
                        <li className="bi-check-circle-fill text-success"> Instagram Integration</li>
                        <li className="bi-check-circle-fill text-success"> YouTube Video Frame</li>
                        <li className="bi-check-circle-fill text-success"> Google Maps Integration</li>
                  </ul>
                    <p><h4>Maintainance </h4>
                      <ul>
                        <li className="bi-check-circle-fill text-success"> Domain Renewal</li>
                        <li className="bi-check-circle-fill text-success"> SSL Renewal</li>
                        <li className="bi-check-circle-fill text-success"> Data Backup</li>
                        <li className="bi-check-circle-fill text-success"> Software Updates</li>
                        <li className="bi-check-circle-fill text-success"> Online Customer Support</li>
                        <li className="bi-check-circle-fill text-success"> Web Hosting & Server Maintainance</li>
                        <li className="bi-check-circle-fill text-success"> SEO-Search Engine Optimisation</li>

                      </ul>
                    </p>
                  
                </p>
                <Button className='btn-get-started'>
                <h5><a href="https://docs.google.com/forms/d/e/1FAIpQLSeuAuCGvFy9TtoXdjromz1txUSsPjEO_zJpyn4CXjSYfVdwaQ/viewform?usp=sf_link">Click to get a quote</a></h5>
                </Button>
              </div>
            </div>
  
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div class="icon-box">
                <div class="icon"><i class="bx bx-file"></i></div>
                <h4><a>Standard Website</a></h4>
                <p>Best for businesses and institutions.</p>
                <p>______________________________________________</p>
                <p>
                <ul>
                    <p><h4>Features</h4></p>
                        <li className="bi-check-circle-fill text-success"> Max Page: 20</li>
                        <li className="bi-check-circle-fill text-success"> Contact Form</li>
                        <li className="bi-check-circle-fill text-success"> Mobile Responsive Website</li>
                        <li className="bi-check-circle-fill text-success"> Carousel Feature</li>
                        <li className="bi-check-circle-fill text-success"> Navigation Sidebar</li>
                        <li className="bi-check-circle-fill text-success"> Footer Section</li>
                        <li className="bi-check-circle-fill text-success"> Dynamic Gallery</li>
                        <li className="bi-check-circle-fill text-success"> Testimonials</li>
                        <li className="bi-check-circle-fill text-success"> Animated Text Effects</li>
                        <li className="bi-check-circle-fill text-success"> Control Pannel</li>
                        <li className="bi-check-circle-fill text-success"> MFA protected User Login</li>
                        <li className="bi-x-circle-fill text-danger"> Search bar</li>
                    <p><h4>Extra Benefits</h4></p>
                        <li className="bi-check-circle-fill text-success"> 1 Free Domain Registration</li>
                        <li className="bi-check-circle-fill text-success"> Free SSL Certification(https://)</li>
                        <li className="bi-check-circle-fill text-success"> 3 Free Revisions Monthly</li>
                        <li className="bi-check-circle-fill text-success"> 3 Free Professional email(30 GB)</li>
                        <li className="bi-x-circle-fill text-danger"> Newsletter Subscribe button</li>
                    <p><h4>Social Media integration </h4></p>
                        <li className="bi-check-circle-fill text-success"> WhatsApp Integration</li>
                        <li className="bi-check-circle-fill text-success"> FaceBook Integration</li>
                        <li className="bi-check-circle-fill text-success"> Instagram Integration</li>
                        <li className="bi-check-circle-fill text-success"> YouTube Video Frame</li>
                        <li className="bi-check-circle-fill text-success"> Google Maps Integration</li>
                  </ul>
                    <p><h4>Maintainance </h4>
                      <ul>
                        <li className="bi-check-circle-fill text-success"> Domain Renewal</li>
                        <li className="bi-check-circle-fill text-success"> SSL Renewal</li>
                        <li className="bi-check-circle-fill text-success"> Data Backup</li>
                        <li className="bi-check-circle-fill text-success"> Software Updates</li>
                        <li className="bi-check-circle-fill text-success"> Online Customer Support</li>
                        <li className="bi-check-circle-fill text-success"> Web Hosting & Server Maintainance</li>
                        <li className="bi-check-circle-fill text-success"> SEO-Search Engine Optimisation</li>

                      </ul>
                    </p>
                  
                </p>
                <Button className='btn-get-started'>
                <h5><a href="https://docs.google.com/forms/d/e/1FAIpQLScm4fZmSksf9jCYCPTf8Rh7W7ZAS3JdgXLzuF7n3MtLi3gjDg/viewform?usp=sf_link">Click to get a quote</a></h5>
                </Button>
              </div>
            </div>


            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
              <div class="icon-box">
                <div class="icon"><i class="bx bx-world"></i></div>
                <h4><a>Premium Plan</a></h4>
                <p>Premium Static/Dynamic Websites for Corporate/Businesses.</p>
                <p>______________________________________________</p>
                <p>
                <ul>
                    <p><h4>Features</h4></p>
                        <li className="bi-check-circle-fill text-success"> Max Pages: 35</li>
                        <li className="bi-check-circle-fill text-success"> Contact Form</li>
                        <li className="bi-check-circle-fill text-success"> Mobile Responsive Website</li>
                        <li className="bi-check-circle-fill text-success"> Carousel Feature</li>
                        <li className="bi-check-circle-fill text-success"> Navigation Sidebar</li>
                        <li className="bi-check-circle-fill text-success"> Footer Section</li>
                        <li className="bi-check-circle-fill text-success"> Dynamic Gallery</li>
                        <li className="bi-check-circle-fill text-success"> Testimonials</li>
                        <li className="bi-check-circle-fill text-success"> Animated Text Effects</li>
                        <li className="bi-check-circle-fill text-success"> Control Pannel</li>
                        <li className="bi-check-circle-fill text-success"> MFA protected User Login</li>
                        <li className="bi-check-circle-fill text-success">Search bar</li>
                    <p><h4>Extra Benefits</h4></p>
                        <li className="bi-check-circle-fill text-success"> 1 Free Domain Registration</li>
                        <li className="bi-check-circle-fill text-success"> Free SSL Certification(https://)</li>
                        <li className="bi-check-circle-fill text-success"> 3 Free Revisions Monthly</li>
                        <li className="bi-check-circle-fill text-success"> 5 Free Professional email(75 GB)</li>
                        <li className="bi-check-circle-fill text-success">Newsletter Subscribe button</li>
                    <p><h4>Social Media integration </h4></p>
                        <li className="bi-check-circle-fill text-success"> WhatsApp Integration</li>
                        <li className="bi-check-circle-fill text-success"> FaceBook Integration</li>
                        <li className="bi-check-circle-fill text-success"> Instagram Integration</li>
                        <li className="bi-check-circle-fill text-success"> YouTube Video Frame</li>
                        <li className="bi-check-circle-fill text-success"> Google Maps Integration</li>
                  </ul>
                    <p><h4>Maintainance </h4>
                      <ul>
                        <li className="bi-check-circle-fill text-success"> Domain Renewal</li>
                        <li className="bi-check-circle-fill text-success"> SSL Renewal</li>
                        <li className="bi-check-circle-fill text-success"> Data Backup</li>
                        <li className="bi-check-circle-fill text-success"> Software Updates</li>
                        <li className="bi-check-circle-fill text-success"> Online Customer Support</li>
                        <li className="bi-check-circle-fill text-success"> Web Hosting & Server Maintainance</li>
                        <li className="bi-check-circle-fill text-success"> SEO-Search Engine Optimisation</li>

                      </ul>
                    </p>
                  
                </p>
                <Button className='btn-get-started'>
                <h5><a href="https://docs.google.com/forms/d/e/1FAIpQLSfZTa5qq_I-beYy_iW7cSRuFWgtqy0MJ-x1Fs6-Zm81osG0Sg/viewform?usp=sf_link">Click to get a quote</a></h5>
                </Button>
              </div>
            </div>
              </div>

            <div id="about" className="about">
              <div className="container mt-4">
                <div className="row">
                <h3>Why to get a Website from us?</h3>
            <p>
              Unlike most other Website Development Companies we do not charge you separately for anything or have any hidden cost.
            </p>

              <div class="tbl-container align-items-center">
              <div class="row tbl-fixed border border danger">
                <div class="table">
                  <table class="table">

                    <thead>
                      <tr>
                        <th>Features</th>
                        <th>Queccasoft Websites</th>
                        <th>Other Companies</th>
                        <th>WordPress Sites</th>
                        <th>Free Website Builders</th>
                      </tr>
                    </thead>

                    <tbody
                    >
                        <tr>
                          <td>Web Hosting</td>
                          <td className="bi-check-circle-fill text-success"> NOT CHARGED SEPARATELY</td>
                          <td className="bi bi-x-circle-fill text-danger"> ONLY 1st YEAR FREE</td>
                          <td className="bi bi-x-circle-fill text-danger"> CHARGED SEPARATELY</td>
                          <td className="bi bi-x-circle-fill text-danger"> ONLY 1st YEAR FREE</td>
                        </tr>

                        <tr>
                        <td>Domain</td>
                        <td className="bi-check-circle-fill text-success"> NOT CHARGED SEPARATELY</td>
                        <td className="bi bi-x-circle-fill text-danger"> ONLY 1st YEAR FREE</td>
                        <td className="bi bi-x-circle-fill text-danger"> CHARGED SEPARATELY</td>
                        <td className="bi bi-x-circle-fill text-danger"> CHARGED SEPARATELY</td>
                        </tr>

                        <tr>
                        <td>Business Email</td>
                        <td className="bi-check-circle-fill text-success"> NOT CHARGED SEPARATELY</td>
                        <td className="bi bi-x-circle-fill text-danger"> ONLY 1st YEAR FREE</td>
                        <td className="bi bi-x-circle-fill text-danger"> CHARGED SEPARATELY</td>
                        <td className="bi bi-x-circle-fill text-danger"> NOT AVAILABLE</td>
                        </tr>

                        <tr>
                        <td>SSL Renewal</td>
                        <td className="bi-check-circle-fill text-success"> NOT CHARGED SEPARATELY</td>
                        <td className="bi-check-circle-fill text-success"> NOT CHARGED SEPARATELY</td>
                        <td className="bi bi-x-circle-fill text-danger"> CHARGED SEPARATELY</td>
                        <td className="bi-check-circle-fill text-success"> FREE</td>
                        </tr>

                        <tr>
                        <td>AMC(Annual Maintainance Charges)</td>
                        <td className="bi-check-circle-fill text-success"> NOT CHARGED SEPARATELY</td>
                        <td className="bi bi-x-circle-fill text-danger"> CHARGED SEPARATELY</td>
                        <td className="bi bi-x-circle-fill text-danger"> NOT AVAILABLE</td>
                        <td className="bi bi-x-circle-fill text-danger"> NOT AVAILABLE</td>
                        </tr>

                        <tr>
                        <td>Customer Support</td>
                        <td className="bi-check-circle-fill text-success"> NOT CHARGED SEPARATELY</td>
                        <td className="bi bi-x-circle-fill text-danger"> CHARGED SEPARATELY</td>
                        <td className="bi bi-x-circle-fill text-danger"> NOT AVAILABLE</td>
                        <td className="bi bi-x-circle-fill text-danger"> NOT AVAILABLE</td>
                        </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
                </div>
              </div>
            </div>

            </div>
          </div>

          </div>
    );
}
export default WebDevelopment;
