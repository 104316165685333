import React,{ useState,useEffect } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Tenders from "./Tenders";
import UserDataService from "../services/UserService";
const NoticesMenu = () => {
	const [portNo,setPortNo]=useState(process.env.REACT_APP_SERVER_PORT);
	const [loggedInUser, setLoggedInUser] = useState({id:21,name:"Robert De Selva"});
	const [userId, setUserId]=useState();
	const [fileUrl, setFileUrl]=useState("/Assets/teacher_00020.jpeg"); //Assets/  teacher_00001.png
	const [userName,setUserName]=useState("Robert Demo Selva");

	const routes=
	[
		{
			name: "Tenders",
			rpath: "Tenders",
			relement: <Tenders />
		}
	];

	useEffect(() => {    
		retrieveLoggedInUser();
	}, []);

	const retrieveLoggedInUser = () => {
		UserDataService.findCurrentUser()
		.then(response => {
			//console.log("retrieve logged in user");
			//console.log(response.data.id);
			setUserName(response.data.name);
			setLoggedInUser(response.data);
			setUserId(response.data.id);
			let yy="http://localhost:"+portNo+"/api/admin/files/teacher/"+response.data.id;
			setFileUrl(yy);
		})
		.catch(e => {
			console.log(e);
		});
	};
	// translate (map) your array of objects into jsx
	const routeComponents = routes.map(({ rpath,relement}) => (
		<Route key={rpath} path={rpath} element={relement}  />
	));

	return (
		<>
			<div className="col-sm-12 m-0 p-1">
				<Routes>{routeComponents}</Routes>
				{Outlet}
			</div>
		</>
		);
	}
	
export default NoticesMenu;