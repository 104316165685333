import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import image1 from '../Assets/Header/Cloud_01.png';
import image2 from '../Assets/Header/servers_03.jpg';
import image3 from '../Assets/Header/servers_02.jpg';
import image4 from '../Assets/Header/qscholar/exam_01.jpg'
import image5 from '../Assets/Header/quampaign/handshake_02.jpg'
import qscholarLogo from "../Assets/Header/Q-SCHOLAR LOGO.png"
import quampaignLogo from '../Assets/Header/logo.png'

import PopUp from './PopUp';
const Home = () => {
  const carouselItems = [
    { image: image1, caption: 'Welcome to ', spanCaption: "Queccasoft", caption2:'We specialise in Cloud Softwares Designing ,ERP and Sales Software Development.',buttonCaption: "Read More" ,url:''},
    { image: image2, caption: 'Why ', spanCaption: "Cloud ?", caption2:'With the increase in the cost of data management and risk of security, Cloud softwares provide excellent solution to both protect data and access it from across the globe!', buttonCaption: "Read More", url:''},
    { image: image3, caption: 'Why ', spanCaption: "Queccasoft ?", caption2:'With rapid digital transformation of businesses, we realise how important it is to be ahead.Thats why we provide Software products and websites which are uniquely designed to match each of our clients needs.', buttonCaption: "Read More", url:''},
    { image: image4, caption: 'Upgrade Your Academic Institution with ', spanCaption: "Q-Scholar !", caption2:'School ERP|Calendar|Report Cards', buttonCaption: "Read More", url:'/Products/QScholar'}
  ];

  return (
    <div>
      <PopUp></PopUp>
      {/* Carousel */}
      <div id='hero'>
      <Carousel className='carousel-container me-auto' fade>
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={item.image}
              alt={item.caption}
            />
            <Carousel.Caption className='carousel-content'>
              <h2>{item.caption}<span>{item.spanCaption}</span></h2>
              {item.caption2 && <p>{item.caption2}</p>}
              {item.url && <Link to={item.url}>
              <Button className="btn-get-started">{item.buttonCaption}</Button>
              </Link>}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      </div>
      <div id="main">

    <div id="featured" className="featured">
      <div className="container">

        <div className="row">
          <div className="col-lg-4">
            <div className="icon-box">
              <i className="bi bi-card-checklist"></i>
              <h3><a>Light Code</a></h3>
              <p>Our Products have light code that require minimum system resources, and are able to provide smooth user experience.</p>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-bar-chart"></i>
              <h3><a>Cost Efficiency</a></h3>
              <p>Large quantity of data often needs regular maintainance just to keep them organised. Cloud systems provide cheap alternative with increased security and global access!</p>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="bi bi-binoculars"></i>
              <h3><a>Analytics</a></h3>
              <p>Automation combined with Reports which are fetched by analysing the data often provides valuable insights!</p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div id="about" className="about">
      <div className="container mt-4">

        <div className="row">
          <div className="col-lg-6">
            <img src={image2} className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 content">
            <h3>Why Queccasoft?</h3>
            <p>
              With rapid digital transformation of businesses, we realise how important it is to be ahead.Thats why we provide 
              Software products which are uniquely designed to match each of our client's needs.
            </p>
            <ul>
              <li><i className="bi bi-check-circle"></i> All of our products are light code and bug free ensuring the best user experience.</li>
              <li><i className="bi bi-check-circle"></i> Running a business isn't always about saving cost through automation.Reports provide key insight.Thats where our products come in!</li>
              <li><i className="bi bi-check-circle"></i> Security is the Key! Our Softwares have multi-layered security which keep the user database intact from any security threat.</li>
            </ul>
            <p>
              We bring you powerful advantages to navigate your digital transformation.
            </p>
          </div>
        </div>

      </div>
    </div>

    <div id="featured" className="featured">
      <div className="container mt-4">

        <div className="row">
          <div className="col-sm-10 offset-1">
            <div className="icon-box">
              <i><img src={qscholarLogo} className='col-sm-1'></img></i>
              <h3><a>Q-SCHOLAR</a></h3>
              <h3><a>School ERP | Academic Management | Report Card Software</a></h3>
              <h5>Boost Your Academic Institution with Q-Scholar ERP.</h5>
                  <ul>
                    <li>Dynamic Report Template</li>
                    <li>Calendar & Routine</li>
                    <li>User & Student's Attendance</li>
                    <li>+20 Types of Reports</li>
                    <li>Substitution Module for Teachers</li>
                    <li>Subject Based Templates:
                      <ul>
                        <li>Subject & Sub Exam Combination</li>
                        <li>Custom Conversion Factor(FOR TOTAL MARKS CALCULATION)</li>
                        <li>Mark Template with differnt FM & PM</li>
                        <li>Roll Over Templates for New Year</li>
                        <li>Year Wise Templates</li>
                      </ul>
                    </li>
                    {/* <li>Admission Enquiry</li> */}
                    <li>Roll-Over Anytime</li>
                    <li>ID Card</li>
                    <li>MFA Protected User Login</li>
                  </ul>
                  <Link to='/Products/QScholar'>
                  <Button className='btn-get-started'>View Details</Button>
                  </Link>
            </div>
          </div>
          
        </div>

      </div>
    </div>

    <div id="services" className="services">
      <div className="container mt-4">

        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-phone-incoming"></i></div>
              <h4><a>Customer Support</a></h4>
              <p>Facing an issue?Don’t worry! We have got you covered.We provide 24x7 Customer Support and on ground assistance!</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4><a>Light Code</a></h4>
              <p>Requires minimum system resources to ensure smooth user experience without any bugs.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4><a>Reports</a></h4>
              <p>Our softwares provide statistical reports on day to day basis to ensure maximum efficiency of your business!</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-world"></i></div>
              <h4><a>Global Access</a></h4>
              <p>Log in from anywhere you want! Thats the advantage of cloud!</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-shield-plus"></i></div>
              <h4><a>Security</a></h4>
              <p>Our Cloud Services offer multi-layered security features such as Multi-Factor Authentication & Firewall that keeps your database safe and secure.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-data"></i></div>
              <h4><a>Backup</a></h4>
              <p>Automatic data backups and safepoint keep your system safe in case of any major security breach.</p>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

      {/* Text paragraph */}
      <p className="mt-5 text-center">
        
      </p>
    </div>
  );
};

export default Home;
