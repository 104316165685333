import http from "../http-common";

const getAll = () => {
  return http.get("/user");
};

const getUserByUserGroupId = (userGroupId) => {
  return http.get(`userGroup/${userGroupId}/user`);
};

const getUserByUserId = userId => {
  return http.get(`/user/${userId}`);
};

const getAllUsersPageWiseByName = (searchName,sortAscDesc,pageLength,pageNo) => {
  return http.get(`/pageWiseUsers/searchName/${searchName}/sortAscDesc/${sortAscDesc}/pageLength/${pageLength}/pageNo/${pageNo}
  `);
};

const createUserByUserGroupId = (userGroupId, data) => {
  return http.post(`/userGroup/${userGroupId}/user`, data);
};

const updateUserByUserGroupIdByUserId = (userGroupId,userId, data) => {
  return http.put(`/userGroup/${userGroupId}/user/${userId}`, data);
};

const removeUserByUserId = userId => {
  return http.delete(`/user/${userId}`);
};

const removeAllUserByUserGroupId = userGroupId => {
  return http.delete(`/userGroup/${userGroupId}/user`);
};

const findByName = (name) => {
  return http.get(`/user/${name}`);
};

const findFileByTeacherId = (teacherId) => {
  return http.get(`/admin/files/teacher/${teacherId}`);
};

const findCurrentUser = () => {
  return http.get(`/admin/user/currentUser`);
}

const upload = (file, onUploadProgress,id) => {
  let formData = new FormData();

  formData.append("file", file);

  return http.post(`/admin/upload/teacher/${id}`, formData,
  {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress,
  });
}

const updateUserByUserRubrix = (userId,rubrix) => {
  console.log("into update user id",userId,"rubrix ",rubrix);
  return http.put(`/user/${userId}/newRubrix/${rubrix}`);
}


const checkUserRubrixAndUpdate = (userId,rubrix) => {
  return http.put(`/user/${userId}/newRubrix/${rubrix}`);
}

const userService = {
  getAll,
  getUserByUserGroupId,
  getUserByUserId,
  createUserByUserGroupId,
  updateUserByUserGroupIdByUserId,
  updateUserByUserRubrix,
  removeUserByUserId,
  removeAllUserByUserGroupId,
  findByName,
  findFileByTeacherId,
  findCurrentUser,
  upload,
  checkUserRubrixAndUpdate,
  getAllUsersPageWiseByName
  
};

export default userService;